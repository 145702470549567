import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import CallMe from '../components/CallMe/CallMe'
import Newsletter from '../components/Forms/Newsletter/Newsletter'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import Banners from '../components/Banners/Banners'
import { Heading2 } from '../components/Typography/Typography'
import { TRUNCATED_HERO_TYPES } from '../constants/truncatedHeroTypes'
import { BG_TYPES } from '../constants/bgTypes'
import { bgTheme, newsletterSpacings } from '../common/utils'
import Wysiwyg from '../components/Wysiwyg/Wysiwyg'
import mapBannersToList from '../common/queries/mapBannersToList'
import HTMLBody from '../components/HTMLBody'

const TermsConditionsPage = ({ data }) => {
  const seoData = data.wp.page?.seo
  const acf = data.wp.page?.termsAcf || {}

  const newsletterBg = bgTheme(acf.newsletterContainerBackground)
  const newsletterSpacing = newsletterSpacings(acf.newsletterBoxMargins)

  const banners = mapBannersToList(acf)

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container bg={BG_TYPES.black}>
        <TruncatedHero
          header={
            <Heading2
              as="h1"
              color="white"
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html: acf.heroFields.title,
              }}
            />
          }
          heroImages={acf.heroFields?.bgImg?.imageFile?.childImageSharp.fluid}
          positionImg="50% 0%"
          heroType={TRUNCATED_HERO_TYPES.withShortHeroOnMobile}
          gradient="top"
          centered
        />

        <Container
          mt={['0', '-4rem', '-17rem']}
          mb={['', '6.25rem']}
          position="relative"
          zIndex={2}
        >
          <Inner>
            <Wysiwyg>
              <HTMLBody color="white" html={acf.content} />
            </Wysiwyg>
          </Inner>
        </Container>
      </Container>

      <Container
        pt={newsletterSpacing}
        pb={newsletterSpacing}
        bg={newsletterBg}
      >
        <Inner pl={['0', '1.875rem']} pr={['0', '1.875rem']}>
          <Newsletter />
        </Inner>
      </Container>

      <Container mb={['1rem', '3.75rem']}>
        <Inner>
          <Banners banners={banners} />
        </Inner>
      </Container>

      <CallMe info={acf.ctaBarText} phone={acf.ctaBarPhone} />
    </Layout>
  )
}

export default TermsConditionsPage

export const query = graphql`
  query {
    wp {
      page(id: "terms-conditions", idType: URI) {
        seo {
          ...seoFragment
        }
        termsAcf {
          content
          underNewsletterBoxLeft {
            title
            content
            bgImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 570) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          underNewsletterBoxRight {
            title
            content
            bgImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 570) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          heroFields {
            title
            bgImg {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ctaBarPhone
          ctaBarText
          newsletterBoxMargins
          newsletterContainerBackground
        }
      }
    }
  }
`
